<template>
  <div id="AmazonPayButton"></div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { omit } from "lodash";
import { useCartStore } from "@/stores/cart";
import { useGlobalStore } from "@/stores/global";

interface Props {
  payloads: any;
}

const props = defineProps<Props>();
const cartStore = useCartStore();
const globalStore = useGlobalStore();

const checkoutPayloadConfig = props.payloads.createCheckoutSessionConfig;

onMounted(() => {
  const amazonPayButton = amazon.Pay.renderButton("#AmazonPayButton", {
    ledgerCurrency: "JPY",
    sandbox: true,

    checkoutLanguage: "ja_JP",
    buttonColor: "Gold",
    ...omit(props.payloads, "createCheckoutSessionConfig"),
  });

  if (props.payloads.signInConfig) return;

  amazonPayButton.onClick(function () {
    if (["normal", "upsell"].includes(cartStore?.orderInfo?.orderMode as string)) {
      cartStore
        .ordersPaymentAmountValidate(
          checkoutPayloadConfig.payloadJSON.paymentDetails.chargeAmount.amount,
          "amazon_pay",
          cartStore.orderInfo?.isRecurring,
        )
        .then(() => {
          amazonPayButton.initCheckout({
            createCheckoutSessionConfig: checkoutPayloadConfig,
          });
        })
        .catch(() => {
          if (globalStore.validationErrors) {
            if (cartStore.currentMode == "upsell") {
              cartStore.cart.productsData = cartStore.preventiveProductsData;
              cartStore.cart.subsOrderData = cartStore.preventiveSubsOrderData;
              cartStore.cart.specifyDeliveryDate = cartStore.preventiveSpecifyDeliveryDate;
              cartStore.cart.urlData.productUpsellId = null;
              cartStore.cart.shippingCarrierId = cartStore.preventiveShippingCarrierId;
            }

            cartStore.cart.urlData.upsellNormal = false;

            globalStore.keepError = true;
            globalStore.keepBaseError = true;

            if (globalStore.resetData) cartStore.$reset();

            window.location.href = "/lp?u=" + cartStore.urlInfo.baseUrl;
          }
        });
    } else {
      amazonPayButton.initCheckout({
        createCheckoutSessionConfig: checkoutPayloadConfig,
      });
    }
  });
});
</script>
